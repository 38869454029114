import React from 'react';
import './Notfound.css'; // Import CSS file for styling

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-text">
        <div className="not-found-4">4</div>
        <div className="not-found-animation">
          <div className="not-found-circle"></div>
          <div className="not-found-circle"></div>
        </div>
        <div className="not-found-4">4</div>
      </div>
      <div className="not-found-message">Oops! Page Not Found</div>
    </div>
  );
};

export default NotFound;
