import axios from  "axios";

const url = process.env.REACT_APP_BASE_URL


export const getQuestions = async () => {
    try {
      const response = await axios.get(`${url}/api/getquestions`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching questions',error);
    }
};

export const getQuestionbyId = async (id) => {
  try {
    const response = await axios.get(`${url}/api/getquestion/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching questions',error);
  }
};
export const updateQuestion = async (id ,updatedData) => {
  try {
    const response = await axios.put(`${url}/api/updatequestion/${id}`, updatedData);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching questions',error);
  }
};


