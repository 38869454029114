import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAccount , getAccounts, DeleteAccount } from '../services/accountService';



export const fetchAccounts = createAsyncThunk('accounts/fetchAccounts', async () => {
  try {
    const response = await getAccounts();
    return response;
  } catch (error) {
    throw new Error('Error fetching comments: ' + error.message);
  }
});

export const fetchAccount = createAsyncThunk('accounts/fetchAccount', async (id) => {
  try {
    const response = await getAccount(id);
    return response;
  } catch (error) {
    throw new Error('Error fetching comment: ' + error.message);
  }
});
export const deleteAccount = createAsyncThunk('accounts/deleteAccount', async (id) => {
  try {
    const response = await DeleteAccount(id);
    return response;
  } catch (error) {
    throw new Error('Error fetching comment: ' + error.message);
  }
});



  const accountSlice = createSlice({
  name: 'accounts',
  initialState: {
    comments: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers:(builder) => {
    builder
    .addCase(fetchAccounts.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchAccounts.fulfilled, (state, action) => {
      state.status = 'succeeded';
      
      state.comments = action.payload.accountsWithUserNames;
    })
    .addCase(fetchAccounts.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(fetchAccount.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchAccount.fulfilled, (state, action) => {
      state.status = 'succeeded';
      
      state.accounts = action.payload.account;
    })
    .addCase(fetchAccount.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(deleteAccount.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(deleteAccount.fulfilled, (state, action) => {
      state.status = 'succeeded';
      
      state.accounts = action.payload;
    })
    .addCase(deleteAccount.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
},
});

export default accountSlice.reducer;