import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getComments , getComment , editComment } from '../services/commentService';



export const fetchComments = createAsyncThunk('comments/fetchComments', async () => {
  try {
    const response = await getComments();
    return response;
  } catch (error) {
    throw new Error('Error fetching comments: ' + error.message);
  }
});

export const fetchComment = createAsyncThunk('comments/fetchComment', async (id) => {
  try {
    const response = await getComment(id);
    return response;
  } catch (error) {
    throw new Error('Error fetching comment: ' + error.message);
  }
});

export const updateComment = createAsyncThunk('comments/updateComment', async ({ id, updatedData }) => {
  try {
    const response = await editComment(id, updatedData);
    return response;
  } catch (error) {
    throw new Error('Error updating comment: ' + error.message);
  }
});




  const commentSlice = createSlice({
  name: 'comments',
  initialState: {
    comments: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers:(builder) => {
    builder
    .addCase(fetchComments.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchComments.fulfilled, (state, action) => {
      state.status = 'succeeded';
      
      state.comments = action.payload.commentsWithUserNames;
    })
    .addCase(fetchComments.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(fetchComment.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchComment.fulfilled, (state, action) => {
      state.status = 'succeeded';
      
      state.comments = action.payload.comment;
    })
    .addCase(fetchComment.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(updateComment.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(updateComment.fulfilled, (state, action) => {
      state.status = 'succeeded';
      
      state.comments = action.payload.updatedComment;
    })
    .addCase(updateComment.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
},
});

export default commentSlice.reducer;