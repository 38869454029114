import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Form,
  Row,
  Col,
  Select,
  Button,
  Collapse,
  Input,
  Modal,
  Spin
} from "antd";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { lang} from "../../helper/constant";
import { emptyCollection, fetchCharts , editCharts} from '../../redux/slices/chartSlice';
import { useSelector, useDispatch } from 'react-redux';
import "./charts.css"

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;

const charts = () => {
  const dispatch = useDispatch();
  const {charts} = useSelector((state) => state.chart);
  const [data, setData] = useState([]);
  const [language, setLanguage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openPanelKey, setOpenPanelKey] = useState(null);
  const [form] = Form.useForm();

  const SubmitHandler =  (lang) => {
    if (language) {

      // console.log("dddddd",lang)
      // setLanguage(lang)
      setLoading(true);
      setData([]);
      dispatch(fetchCharts(lang));
    }
  };

  const getData = async () => {
    let dataArray = [];
   
    if (charts && charts.length > 0) {
      const innerData = Array.isArray(charts[0]) ? charts[0] : charts;
      // console.log(innerData)
  
      innerData.forEach((item) => {
        Object.keys(item).forEach((compatibilityKey) => {
          if (compatibilityKey !== 'ID' && compatibilityKey !== '_id') {
            const compatibilityData = item[compatibilityKey];
            dataArray.push({
              name: compatibilityKey ? compatibilityKey : null,
              SUN: compatibilityData ? compatibilityData[0] : null,
              ASCENDANT  : compatibilityData ? compatibilityData[1] : null,
              MOON  : compatibilityData ? compatibilityData[2] : null,
            });
          }
        });
      });
  
      // Sort the dataArray alphabetically based on the 'name' property
      dataArray.sort((a, b) => (a.name > b.name) ? 1 : -1);
      // console.log("ssssss",dataArray)
      setLoading(false);
      setData(dataArray);
    } else {
      // Handle the case when compabilities is empty
    }
  };

  const langSelectHandler = (selected) => setLanguage(selected);

  const onChanageFinish = async (formData) => {
    try {
      formData = formData.items;
      formData.forEach((item) => {
        // Only update the data for the currently opened panel
        if (item.name === openPanelKey) {
          const texts = {
            texts: [item.SUN, item.ASCENDANT, item.MOON]
          };
          dispatch(editCharts({lang : language,key:item.name, data: texts}));
          NotificationManager.success("Updated successfully");
          setLoading(true);
        }
    
      });
 
      // await dispatch(fetchCharts(language));
      // getData();
      setLoading(false);
      
    } catch (error) {
      console.error("Error:", error);
      NotificationManager.error("Error Updating compatibility");
    }
  };

  const handleReset = () => {
    // Reset form fields
    form.resetFields();
    // Reset data and language state
    setData([]);
    window.location.reload();
  };

  useEffect(() => {
    if (charts) {
      getData();
    }
  }, [charts]);

  // console.log( "charts", form.getFieldsValue())  
  // console.log("fffffff",charts)
  

  if (charts) {
    // Construct initial values object
    const initialValues = {};
    data.forEach(item => {
      // console.log(item)
      initialValues[item.name] = {
        SUN: item.SUN,
        ASCENDANT: item.ASCENDANT,
        MOON: item.MOON
      };
    });
    // console.log("iiiiiiiiiiiiiiiiiii",data)
  
    // Set initial values for the form
    form.setFieldsValue({
      items: data
    });
  }

 

  useEffect(() => {
    setData([]);
  }, []);

  
  return (
    <Card
      className="gx-card"
      title={
        <div className="d-flex justify-content-between">
          <p>Charts</p>
          {/* <Link to={`add`} className="ant-btn ant-btn-primary">
            <p>New</p>
          </Link> */}
        </div>
      }
    >
      {/* <Form 
      form={form}
       onFinish={SubmitHandler}> */}
        <Row>
          <Col lg={6} className="mx-3">
            <Form.Item name="lang" label="Language">
            <Select defaultValue=""  onChange={langSelectHandler}>
              <Option value="" disabled>
                  Select Language
                </Option>
                {lang.map(({ value, label }) => (
                  <Option value={value} key={label}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={4} className="charts-btn">
            <Button type="primary" onClick={()=>SubmitHandler(language)}>
              Search
            </Button>
            <Button type="primary" onClick={handleReset}>
              Reset
            </Button>
          </Col>
        </Row>
      {/* </Form> */}
      <Spin spinning={loading}>
        <Form form={form} onFinish={onChanageFinish}>
          {data.length > 0 ? (
            <Form.List name="items" initialValue={data}>
              {(fields, { add, remove }) => ( 
                <Collapse
                  accordion
                  onChange={(key) => setOpenPanelKey(key)}// Update the openPanelKey when a panel is opened
                >
                  {fields.map((field) => (
                    <Panel
                      key={data[field.name].name} // Use a unique key for each panel
                      header={data[field.name] ? data[field.name].name : null}
                    >
                      <Form.Item
                        {...field}
                        label={"SUN"}
                        name={[field.name, "SUN"]}
                        fieldKey={[field.fieldKey, "VIDEO_TEXT"]}
                        className="px-3"
                      >
                        <TextArea showCount rows={8} />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label={"ASCENDANT"}
                        name={[field.name, "ASCENDANT"]}
                        fieldKey={[field.fieldKey, "VIDEO_TEXT"]}
                        className="px-3"
                      >
                        <TextArea showCount rows={8}/>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label={"MOON"}
                        name={[field.name, "MOON"]}
                        fieldKey={[field.fieldKey, "VIDEO_TEXT"]}
                        className="px-3"
                      >
                        <TextArea showCount rows={8}/>
                      </Form.Item>
                    </Panel>
                  ))}
                </Collapse>
              )}
            </Form.List>
          ) : null}
          {data.length > 0 && (
            <div className="d-flex justify-content-end mt-3">
              <Button htmlType="submit" type="primary">
                Submit
              </Button>
            </div>
          )}
        </Form>
      </Spin>
      <Modal
        visible={isModalVisible}
        onOk={() => setIsModalVisible(null)}
        onCancel={() => setIsModalVisible(null)}
        bodyStyle={{ width:'500px' }}
        destroyOnClose={true}
      >
        <video height="700" controls autoPlay style={{ display:'block' , margin: 'auto' }}>
          <source src={isModalVisible} type="video/mp4"/>
        </video>
      </Modal>
    </Card>
  );
};

export default charts;
