import logo from "../../assets/logo.svg";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Checkbox, Form, Input, message } from "antd";
import { logInByPassword } from "../../helper/authentication";
import { NotificationManager } from "react-notifications";
import { authenticate } from "../../redux/AuthReducer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const emial_pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/

const SignIn = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };



  const onFinish = async (formData) => {
    try {
      setIsLoading(true);
      const { email, password } = formData;
     const res = await logInByPassword(email, password);
     console.log(res)
     if (res.data.success === false) {
      // If there's an error message in the response, display it
      NotificationManager.error(res.data.msg);
    } else {
      // If no error message, proceed with successful login
      dispatch(authenticate(res.data.token));
      NotificationManager.success(res.data.msg);
      // Navigate to Horoscopes page
      history.push("/horoscopes/");
    }
    } catch (error) {
      console.error(error);
      NotificationManager.error("Invalid Credentials")
      setIsLoading(false);
    }
  };

  return (
    <div className="gx-app-login-container">
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div
            className="gx-app-login-main-content"
            style={{ minWidth: "650px" }}
          >
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg px-4">
                <img src={logo} />
              </div>
              <div className="gx-app-logo-wid"></div>
            </div>
            <div className="gx-app-login-content my-4">
              <Form
                initialValues={{ remember: true }}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="gx-signin-form gx-form-row0"
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "The input is not valid E-mail!",
                      pattern: emial_pattern,
                    },
                  ]}
                  name="email"
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                  name="password"
                >
                 <Input.Password visibilityToggle={true} placeholder="Password" />
                </Form.Item>
                <Form.Item>
                  <div className="d-flex justify-content-end">
                    {isLoading ? (
                      <div>Loading...</div>
                    ) : (
                      <Button
                        type="primary"
                        className="gx-mb-0"
                        htmlType="submit"
                        loading={isLoading}
                      >
                        Sign In
                      </Button>
                    )}
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
