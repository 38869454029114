import React from "react";
import { Avatar, Popover } from "antd";
import { logOut } from "../../helper/authentication";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const UserProfile = () => {
  const url = process.env.REACT_APP_BASE_URL
  const photo = sessionStorage.getItem('profile');
  const token = sessionStorage.getItem('token');
  //Logout Point Solve
  let history = useHistory();
  const logout = () => {
    logOut(token);
    history.push("/");
  };
  const handleChangePassword = () => {
    history.push("/changepassword/");
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => handleChangePassword()}>Change Password</li>
      <li></li>
      <li onClick={() => logout()}>Logout</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center  gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          src={`${url}/profile/${photo}`}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />
        <span className="gx-avatar-name">
          {sessionStorage.getItem("user")}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
