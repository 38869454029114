import React, { useState, useEffect } from "react";
import { Card, Table, Form, Row, Col, Select, Button, Modal,Spin } from "antd";
import { Link } from "react-router-dom";
import { lang, horoscopesOptions, dateType } from "../../helper/constant";
import { emptyCollection, fetchvideoCollection } from "../../redux/slices/horoScopeSlice";
import { useSelector, useDispatch } from "react-redux";
import "./HoroscopesList.css"; 

const { Option } = Select;

const HoroscopesList = () => {
  const dispatch = useDispatch();
  const { collection } = useSelector((state) => state.videoCollection);
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(null);
  const [Horscope, setHorscope] = useState(null);
  const [Period, setPeriod] = useState(null);


  const [form] = Form.useForm();

  const SubmitHandler = (lang, horoscope, period) => {
    if (language && Horscope && Period) {
    setLoading(true);
    dispatch(fetchvideoCollection(lang, horoscope, period));
    }
  };
  // console.log(collection)

  const getData = (collection) => {
    let dataArray = [];

    if (collection && collection.length > 0) {
      const innerData = collection[0] ? collection[0] : collection;
      innerData.forEach((item) => {
        const date = item.RANGE ? item.RANGE : item.DATE;
        const video_url = item.URL ? item.URL : null;
        const text = item.TEXT ? item.TEXT : null;
        const month = item.MONTH ? item.MONTH : null;
        dataArray.push({ date, video_url, text, month });
      });
    }
    setLoading(false);
    setData(dataArray.reverse());
  };

  const playVideoHandler = (url) => setIsModalVisible(url);
  const langSelectHandler = (selected) => setLanguage(selected);
  const horoSelectHandler = (selected) => setHorscope(selected);
  const periodSelectHandler = (selected) => setPeriod(selected);

  const columns = [
    { title: "Date", key: "date", dataIndex: "date", width: 80 },
    {
      title: "Video",
      key: "video_url",
      dataIndex: "video_url",
      width: 150,
      render: (url) => <div onClick={() => playVideoHandler(url)}>{url}</div>,
      ellipsis: true,
    },
    {
      title: "Text",
      key: "text",
      dataIndex: "text",
      width: 150,
      ellipsis: true,
    },
  ];

  // If the selected period is 'monthly', include the 'Month' column
  if (form.getFieldValue("period") === "MONTHLY" && data.length>0) {
    columns.push({
      title: "Month",
      key: "month",
      dataIndex: "month",
      width: 80,
    });
  }
  if (form.getFieldValue("period") === "WEEKLY" && data.length>0) {
    columns.push({
      title: "Month",
      key: "month",
      dataIndex: "month",
      width: 80,
    });
  }

  const handleReset = () => {
    form.resetFields();
    setData([]);
    window.location.reload();
  };

  useEffect(() => {
    if (collection) {
      getData(collection);
    }
  }, [collection]);

  useEffect(() => {
    dispatch(emptyCollection());
  }, []);

  //  console.log(collection)

  return (
    <Card
      className="gx-card"
      title={
        <div className="d-flex justify-content-between">
          <p>Horoscopes</p>
          <Link to={`add`} className="ant-btn ant-btn-primary">
            <p>New</p>
          </Link>
        </div>
      }
    >
      <Form form={form} onFinish={SubmitHandler}>
        <Row>
          <Col lg={6} className="mx-3">
            <Form.Item name="lang" label="Language">
              <Select defaultValue="" onChange={langSelectHandler}>
                <Option value="" disabled>
                  Select Language
                </Option>
                {lang.map(({ value, label }) => (
                  <Option value={value} key={label}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={6} className="mx-3">
            <Form.Item name="horoscope" label="horoscope">
              <Select defaultValue="" onChange={horoSelectHandler}>
              <Option value="" disabled>
                  Select Horoscope
                </Option>
                {horoscopesOptions.map(({ value, label }) => (
                  <Option vlaue={value} key={label}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={6} className="mx-3">
            <Form.Item name="period" label="Period">
              <Select defaultValue=""  onChange={periodSelectHandler}>
              <Option value="" disabled>
                  Select Period
                </Option>
                {dateType.map(({ value, label }) => (
                  <Option vlaue={value} key={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={4} className="btns">
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button type="primary" onClick={handleReset}>
              Reset
            </Button>
          </Col>
        </Row>
      </Form>
      <Spin spinning={loading} > {/* Display Spin component while loading */}
      <Table dataSource={data} columns={columns} rowKey="date" />
      </Spin>
      <Modal
        visible={isModalVisible}
        onOk={() => setIsModalVisible(null)}
        onCancel={() => setIsModalVisible(null)}
        bodyStyle={{ width: "500px" }}
        destroyOnClose={true}
      >
        <video
          height="700"
          controls
          autoPlay
          style={{ display: "block", margin: "auto" }}
        >
          <source src={isModalVisible} type="video/mp4" />
        </video>
      </Modal>
    </Card>
  );
};

export default HoroscopesList;
