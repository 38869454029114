import React, { useState, useEffect } from "react";
import { useParams , useHistory } from "react-router-dom";
import { Card, Form, Input , Button } from "antd";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from 'react-redux';
import { fetchquestionbyId , UpdateQuestion } from '../../redux/slices/questionSlice';


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 8 },
  },
};

const { Item } = Form;
const { TextArea } = Input;

const QuestionForm = () => {
  const {id} = useParams();
  // console.log(id)
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {questions} = useSelector((state) => state.question);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  
  

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        await dispatch(fetchquestionbyId(id));
        if (!questions) {
          NotificationManager("Record Not Found");
        }
      } catch (error) {
        console.error('Error fetching question:', error);
      }
    };
    fetchData(id)
  }, [dispatch,id]);


  if (questions) {
  form.setFieldsValue({
    LANG: questions?.question?.LANG || '', 
    ASSIGNED_ASTRO: questions?.question?.ASSIGNED_ASTRO || '',
    FULL_NAME: questions?.question?.USER_DETAIL?.FULL_NAME || '',
    QUESTION_TEXT: questions?.question?.QUESTION_TEXT || '',
    ANSWER_TEXT: questions?.answer?.ANSWER_TEXT || '',
  });
}


  const onFinish = async (values) => {
    try {
      setLoading(true);
      const updatedData = values;
      await dispatch(UpdateQuestion({id, updatedData:updatedData}));
      // console.log(updatedData)
      NotificationManager.success("Question updated successfully");
      dispatch(fetchquestionbyId(id));
      history.push('/questions/');
    } catch (error) {
      console.error('Error updating question:', error);
      NotificationManager.error("Error updating question");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="gx-card" title="Quesiton Detail">
      <Form
        {...formItemLayout}
        scrollToFirstError
        form={form}
        name=""
        onFinish={onFinish}
        initialValues={{
          LANG: questions?.question?.LANG || '', 
          ASSIGNED_ASTRO: questions?.question?.ASSIGNED_ASTRO || '',
          FULL_NAME: questions?.question?.USER_DETAIL?.FULL_NAME || '',
          QUESTION_TEXT: questions?.question?.QUESTION_TEXT || '',
          ANSWER_TEXT: questions?.answer?.ANSWER_TEXT || '',
        }}
      >
        <Item name="LANG" label="Language">
          <Input disabled/>
        </Item>
        <Item name="ASSIGNED_ASTRO" label="Assigned to">
          <Input/>
        </Item>
        <Item name="FULL_NAME" label="User">
          <Input/>
        </Item>
        <Item name="QUESTION_TEXT" label="Question Text">
          <TextArea/>
        </Item>
        <Item name="ANSWER_TEXT" label="answer">
          <TextArea rows={6}/>
        </Item>
        <Item className="d-flex justify-content-end">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Item>
      </Form>
    </Card>
  );
};
export default QuestionForm;
