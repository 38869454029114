import React, { useState, useEffect } from "react";
import { Card, Table, Button, Input, Space, Spin } from "antd";
import { Link } from "react-router-dom";
import { fetchUsers } from '../../redux/slices/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from 'react-highlight-words';

const UsersList = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);

  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [loading, setLoading] = useState(false); // State to manage loading

  const getData = async () => {
    setLoading(true); // Set loading to true when fetching starts
    try {
      const dataArray = [];
      const querySnapshot = await dispatch(fetchUsers());
      querySnapshot.payload ? querySnapshot.payload.users.forEach(doc => {
        dataArray.push({
          id: doc._id,
          userId: doc.USER_ID,
          user_name: doc.USER_NAME,
          full_name: doc.FULL_NAME
        });
      }) : null;
      console.log(dataArray);
      setData(dataArray.reverse());
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false); // Set loading back to false when fetching is completed
    }
  };

  useEffect(() => {
    getData();
  }, [dispatch]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    // { title: "User Id", key: "USER_ID", dataIndex: "userId" },
    { title: "User Name", key: "USER_NAME", dataIndex: "user_name", ...getColumnSearchProps('user_name') },
    { title: "Full Name", key: "FULL_NAME", dataIndex: "full_name", ...getColumnSearchProps('full_name') },
    {
      title: "Action",
      key: "edit",
      render: (art) => (
        <div>
          <Link to={`/update/${art.id}`}>
            <Button type="link" icon={<EditOutlined />}
             style={{ backgroundColor: "black" , color:"white" }}
             >
            </Button>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Card
      className="gx-card"
      title={
        <div className="d-flex justify-content-between">
          <p>Users</p>
        </div>
      }
    >
      <Spin spinning={loading}> {/* Display Spin component while loading */}
        <Table dataSource={data} columns={columns} rowKey="id" />
      </Spin>
    </Card>
  );
};

export default UsersList;
