import React, { useState } from "react";
import { Card, Form, Select, DatePicker, Input, Button } from "antd";
import { lang, horoscopesOptions, dateType } from "../../helper/constant";
import { doc, setDoc, getDoc, getFirestore } from "firebase/firestore";
import { NotificationManager } from "react-notifications";
import { generateId } from "../../helper/generateId";
import { addCompatibilties  } from '../../redux/slices/compabilitySlice';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 8 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 16,
    },
  },
};

const CompatibilityForm = () => {
  const dispatch = useDispatch();
  const {compabilities} = useSelector((state) => state.compability);
  const [form] = Form.useForm();
  const [periodType, setPeriodType] = useState("date");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // Custom validator function to check for blank spaces
  const validateText = (_, value) => {
    if (value && value.trim() !== '') {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Text is required'));
  };
  const validateURL = (_, value) => {
    if (value && value.trim() !== '') {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Video Url is required'));
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const { horoscope1, horoscope2, ...rest } = values; // Destructure horoscopes and other form values
      const concatenatedHoroscopes = `${horoscope1}${horoscope2}`; // Concatenate horoscopes
      const data = { ...rest, horoscope: concatenatedHoroscopes }; // Include concatenated horoscopes in data
      const apiRes = await dispatch(addCompatibilties({ data: data }));
      if(apiRes.payload.success === true){
      NotificationManager.success("Added successfully");
      history.push('/compatibility/');
      form.resetFields();
      }else{
        NotificationManager.error(apiRes.payload.msg);
      }
    } catch (error) {
      console.error('Error updating question:', error);
      NotificationManager.error("Error adding compatibility");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (formData) => {};

  return (
    <Card className="gx-card" title="New Compatibility">
      <Form
        {...formItemLayout}
        scrollToFirstError
        name="basic"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Language"
          name="lang"
          rules={[{ required: true, message: "Required" }]}
        >
          <Select>
            {lang.map(({ value, label }) => (
              <Option value={value} key={label}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Horoscope 1"
          name="horoscope1"
          rules={[{ required: true, message: "Required" }]}
        >
          <Select>
            {horoscopesOptions.map(({ value, label }) => (
              <Option value={value} key={label}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Horoscope 2"
          name="horoscope2"
          rules={[{ required: true, message: "Required" }]}
        >
          <Select>
            {horoscopesOptions.map(({ value, label }) => (
              <Option value={value} key={label}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Video URL"
          name="video_url"
          rules={[{ validator: validateURL }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Text"
          name="video_text"
          rules={[
            { validator: validateText } 
          ]}
        >
          <TextArea showCount />
        </Form.Item>
        <Form.Item
          {...tailFormItemLayout}
          className="d-flex justify-content-end"
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default CompatibilityForm;
