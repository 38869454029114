import React, { useState, useEffect } from "react";
import { useParams , useHistory } from "react-router-dom";
import { Card, Form, Input , Button } from "antd";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from 'react-redux';
import { fetchComment ,updateComment  } from '../../redux/slices/commentSlice';


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 8 },
  },
};

const { Item } = Form;
const { TextArea } = Input;

const CommentForm = () => {
  const {id} = useParams();
  // console.log(id)
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {comments} = useSelector((state) => state.comment);
  const [loading, setLoading] = useState(false);
  const history = useHistory();


  const validateRate = (_, value) => {
    if (value && value.trim() !== '') {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Rate is required'));
  };
  const validateComment = (_, value) => {
    if (value && value.trim() !== '') {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Comment is required'));
  };

  
  

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        await dispatch(fetchComment(id));
        if (!comments) {
          NotificationManager("Record Not Found");
        }
      } catch (error) {
        console.error('Error fetching question:', error);
      }
    };
    fetchData(id)
  }, [dispatch,id]);

  // console.log(comments)


  if (comments) {
  form.setFieldsValue({
    comment: comments?.COMMENT || '',
    rate: comments?.RATE || '',
  });
}


  const onFinish = async (values) => {
    try {
      setLoading(true);
      const updatedData = values;
      const apiRes = await dispatch(updateComment({id, updatedData:updatedData}));
      if(apiRes.payload.success === true){
      NotificationManager.success("Comment updated successfully");
      dispatch(fetchComment(id));
      history.push('/comments/');
      form.resetFields();
      }else{
        NotificationManager.error(apiRes.payload.msg);
      }
    } catch (error) {
      console.error('Error updating comment:', error);
      NotificationManager.error("Error updating comment");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="gx-card" title="Update Comment">
      <Form
        {...formItemLayout}
        scrollToFirstError
        form={form}
        name=""
        onFinish={onFinish}
        initialValues={{
            comment: comments?.COMMENT || '',
            rate: comments?.RATE || '',
        }}
      >
        <Item name="rate" label="Rate" rules={[{ required: true,validator: validateRate }]}>
          <Input />
        </Item>
        <Item name="comment" label="Comment" rules={[{ required: true,validator: validateComment }]}>
          <TextArea rows={6} />
        </Item>
        <Item className="d-flex justify-content-end">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Item>
      </Form>
    </Card>
  );
};
export default CommentForm;
