import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Card, Form, Input, Button, Modal } from "antd"; // Import Modal from Ant Design
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from 'react-redux';
import { fetchAccount,deleteAccount } from '../../redux/slices/accountSlice';
import moment from "moment";


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 8 },
  },
};

const { Item } = Form;
const { TextArea } = Input;

const AccountForm = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { accounts } = useSelector((state) => state.account);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        await dispatch(fetchAccount(id));
        if (!accounts) {
          NotificationManager("Record Not Found");
        }
      } catch (error) {
        console.error('Error fetching question:', error);
      }
    };
    fetchData(id)
  }, [dispatch, id]);

  useEffect(() => {
    if (accounts) {
      const formattedDateTime = moment(accounts.date, "MMMM DD, YYYY [at] h:mm:ss A [UTC]Z").format('DD/MM/YYYY,HH:mm');
      form.setFieldsValue({
        userId: accounts?.userId || '', 
        username: accounts?.userName || '',
        useremail: accounts?.userEmail || '',
        Date: formattedDateTime,
        Reason: accounts?.reason || '',
        Comment: accounts?.comment || '',
      });
    }
  }, [accounts]);

  const onFinish = () => {
    Modal.confirm({
      title: 'Delete Account Permanently',
      content: 'Are you sure you want to delete this account permanently?',
      onOk() {
        // Call your delete API or logic here
       dispatch(deleteAccount(id));
        NotificationManager.success('Account deleted successfully');
        // Redirect to a different page after deletion if needed
        history.push('/accounts/');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <Card className="gx-card" title="Account Details">
      <Form
        {...formItemLayout}
        scrollToFirstError
        form={form}
        name=""
        onFinish={onFinish}
      >
        <Item name="userId" label="User Id">
          <Input disabled style={{ color: 'rgba(0, 0, 0, 0.85)' }} />
        </Item>
        <Item name="username" label="Username">
          <Input disabled style={{ color: 'rgba(0, 0, 0, 0.85)' }} />
        </Item>
        <Item name="useremail" label="User Email">
          <Input disabled style={{ color: 'rgba(0, 0, 0, 0.85)' }} />
        </Item>
        <Item name="Date" label="Date">
          <Input disabled style={{ color: 'rgba(0, 0, 0, 0.85)' }} />
        </Item>
        <Item name="Reason" label="Reason">
          <TextArea disabled style={{ color: 'rgba(0, 0, 0, 0.85)' }} />
        </Item>
        <Item name="Comment" label="Comment">
          <TextArea rows={6} disabled style={{ color: 'rgba(0, 0, 0, 0.85)' }} />
        </Item>
        <Item className="d-flex justify-content-end">
          <Button type="primary" onClick={onFinish}>
            Delete this account permanently
          </Button>
        </Item>
      </Form>
    </Card>
  );
};
export default AccountForm;