import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../redux/slices/userSlice";

const SendPushNotificationForm = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.users);
  const url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${url}/api/send`, {
        userId: selectedUser.value,
        message,
      });
      // console.log("Push notification sent successfully");
    } catch (error) {
      console.error("Error sending push notification:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Select
        value={selectedUser}
        onChange={handleUserChange}
        options={users.map((user) => ({
          value: user._id,
          label: user.USER_NAME,
        }))}
        placeholder="Select User"
        styles={{ width: 200, minHeight: 30 }} 
        isSearchable
        required
      />
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Message"
        style={{ width: 400, height: 30, margin: "10px 0" }}
        required
      />
      <button type="submit" style={{ width: 150, height: 40, backgroundColor: "blue", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }}>Send</button>
    </form>
  );
};

export default SendPushNotificationForm;





//------for EMAIL NOTIFICATION-------//

// import React, { useEffect,useState } from 'react';
// import axios from 'axios';
// import { Input } from "antd";
// import { fetchUsers } from "../../redux/slices/userSlice";
// import Select from "react-select";
// import { useDispatch,useSelector } from 'react-redux';
// const { TextArea } = Input;

// const SendPushNotificationForm = () => {
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [message, setMessage] = useState("");
//   const [subject, setSubject] = useState("");
//   const dispatch = useDispatch(); 
//   const users = useSelector((state) => state.user.users);
//   const url = process.env.REACT_APP_BASE_URL;

//  useEffect(() => {
//   dispatch(fetchUsers());
//   }, [dispatch]);

//     const handleUserChange = (selectedOption) => {
//     setSelectedUser(selectedOption);
//   };

//      const handleSubmit = async (e) => {
//      e.preventDefault();
//     try {
//        await axios.post(`${url}/api/send-email`, {
//          userId: selectedUser.value,
//          subject,
//          message,
//        });
//        console.log("Push notification sent successfully");
//      } catch (error) {
//        console.error("Error sending push notification:", error);
//      }
//    };

//      return (
//      <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
//        <Select
//          value={selectedUser}
//          onChange={handleUserChange}
//          options={users.map((user) => ({
//            value: user._id,
//            label: user.FULL_NAME,
//          }))}
//          placeholder="Select User"
//          styles={{ width: 200, minHeight: 30 }} 
//          isSearchable
//          required
//        />
//        <input
//          type="text"
//          value={subject}
//          onChange={(e) => setSubject(e.target.value)}
//          placeholder="subject"
//          style={{ width: 500, height: 30, margin: "10px 0" }}
//          required
//        />
//        <TextArea rows={6}
//          type="text"
//          value={message}
//          onChange={(e) => setMessage(e.target.value)}
//          placeholder="Message"
//          style={{ width: 500, height: 300, margin: "10px 0" }}
//          required
//        />
//        <button type="submit" style={{ width: 150, height: 40, backgroundColor: "blue", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }}>Send</button>
//      </form>
//    );
//  };

// export default SendPushNotificationForm;
