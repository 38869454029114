import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getVideocollections , createVideocollection } from '../services/horoscopeService';



export const fetchvideoCollection = createAsyncThunk('videoCollections/fetchvideoCollection', async ({ lang, horoscope, period }) => {
  try {
    const response = await getVideocollections(lang, horoscope, period);
    return response;
  } catch (error) {
    throw new Error('Error fetching video collection: ' + error.message);
  }
});

export const createvideoCollection = createAsyncThunk('videoCollections/createvideoCollection', async ({ data }) => {
  try {
    const response = await createVideocollection(data);
    return response;
  } catch (error) {
    throw new Error('Error creating video collection: ' + error.message);
  }
});



  const videoCollectionSlice = createSlice({
  name: 'videoCollection',
  initialState: {
    collection: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    emptyCollection: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.collection = []
    },
  },
  extraReducers:(builder) => {
    builder
    .addCase(fetchvideoCollection.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchvideoCollection.fulfilled, (state, action) => {
      state.status = 'succeeded';
      
      state.collection = action.payload.filteredData;
    })
    .addCase(fetchvideoCollection.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(createvideoCollection.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(createvideoCollection.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log(action)
      state.collection = action.payload.filteredData;
    })
    .addCase(createvideoCollection.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
},

});

export const { emptyCollection } = videoCollectionSlice.actions
export default videoCollectionSlice.reducer;