import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUsers , getUserbyId , updateUser , resetPassowrd , changePassword} from '../services/userService';


export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  try {
    const response = await getUsers();
    return response;
  } catch (error) {
    throw new Error('Error fetching users: ' + error.message);
  }
});

export const fetchUserbyId = createAsyncThunk('users/fetchUserbyId', async (id) => {
  try {
    const response = await getUserbyId(id);
    return response;
  } catch (error) {
    throw new Error('Error fetching user by ID: ' + error.message);
  }
});

export const UpdateUser = createAsyncThunk('users/UpdateUser', async ({ id, updatedData }) => {
  try {
    const response = await updateUser(id, updatedData);
    return response;
  } catch (error) {
    throw new Error('Error updating user: ' + error.message);
  }
});

export const resetPass = createAsyncThunk('users/resetPass', async ({ resetToken , password }) => {
  try {
    const response = await resetPassowrd(resetToken, password);
    return response;
  } catch (error) {
    throw new Error('Error updating user: ' + error.message);
  }
});
export const updatePassword = createAsyncThunk('users/updatePassword', async ({ id ,newPassword,oldPassword }) => {
  try {
    const response = await changePassword(id ,newPassword,oldPassword);
    return response;
  } catch (error) {
    throw new Error('Error updating user: ' + error.message);
  }
});



  const usersSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers:(builder) => {
    builder
    .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
    .addCase(fetchUsers.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.users = action.payload.users;
    })
    .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchUserbyId.pending, (state) => {
        state.status = 'loading';
      })
    .addCase(fetchUserbyId.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.log(action)
      state.users = action.payload.user;
    })
    .addCase(fetchUserbyId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(UpdateUser.pending, (state) => {
        state.status = 'loading';
      })
    .addCase(UpdateUser.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.users = action.payload.user;
    })
    .addCase(UpdateUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(resetPass.pending, (state) => {
        state.status = 'loading';
      })
    .addCase(resetPass.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.users = action.payload.user;
    })
    .addCase(resetPass.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updatePassword.pending, (state) => {
        state.status = 'loading';
      })
    .addCase(updatePassword.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.users = action.payload.user;
    })
    .addCase(updatePassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  },
});

export default usersSlice.reducer;