import React, {useState} from "react";
import {Layout, Popover} from "antd";
import { Link } from "react-router-dom";
import UserProfile from './UserProfile'


const {Header} = Layout;

const Topbar = () => {
  return (
    <Header>
      <Link to="/">
        <img alt="" className="gx-d-block gx-d-lg-none gx-pointer" src={("/images/w-logo.png")}/></Link>
        <UserProfile />
    </Header>
  );
};

export default Topbar;
