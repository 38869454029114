import React, { useEffect } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import SidebarLogo from "./SidebarLogo";
import AppsNavigation from "./AppsNavigation";
import Routes from "../../routes/routes";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = () => {
  const navStyle = "NAV_STYLE_FIXED";
  const themeType = "THEME_TYPE_SEMI_DARK";

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === "") {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = "";
  const defaultOpenKeys = "";
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <AppsNavigation />
        <Menu
          defaultOpenKeys={[defaultOpenKeys]}
          selectedKeys={[selectedKeys]}
          theme={"dark"}
          mode="inline"
        >
          <MenuItemGroup key="main" className="gx-menu-group"  >
            <>
            {Routes.filter((route) => route.nav).map((route) => (
              <Menu.Item key={route.path}>
                <Link to={route.path} activeClassName="selected">
                    {route.icon}
                    <span>{route.title}</span>
                </Link>
              </Menu.Item>
            ))}
            </>
          </MenuItemGroup>
        </Menu>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
