import axios from  "axios";
import { authHeader } from "../../helper/Auth";

const url = process.env.REACT_APP_BASE_URL


export const getPolicy = async () => {
    try {
      const response = await axios.get(`${url}/api/getsettings`,{
        headers: authHeader()
      });
      return response.data;
    } catch (error) {
      throw new Error('Error fetching policy',error);
    }
};

export const updatePolicy = async (id, SETTING_ID, updatedData) => {
  try {
    const response = await axios.put(`${url}/api/updatesetting`,{
    _id : id ,
    SETTING_ID : SETTING_ID , 
    updatedData  : updatedData
    },{
      headers: authHeader()
    }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error fetching policy',error);
  }
};