import axios from  "axios";
import { authHeader } from "../../helper/Auth";

const url = process.env.REACT_APP_BASE_URL;


export const getUsers = async () => {
    try {
      const response = await axios.get(`${url}/api/getusers`,{
        headers: authHeader()
      });
      return response.data;
    } catch (error) {
      throw new Error('Error fetching users',error);
    }
};

export const getUserbyId = async (id) => {
  try {
    const response = await axios.get(`${url}/api/getuser/${id}`,{
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching users',error);
  }
};

export const updateUser = async (id ,updatedData) => {
  try {
    const response = await axios.put(`${url}/api/edituser/${id}`, updatedData ,{
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching users',error);
  }
};

export const resetPassowrd = async (resetToken ,password) => {
  try {
    const response = await axios.patch(`${url}/api/reset/${resetToken}`, password);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching users',error);
  }
};

export const changePassword = async (id ,newPassword,oldPassword) => {
  try {
    const response = await axios.put(`${url}/api/updatepassword`, {id,newPassword,oldPassword},{
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching users',error);
  }
};

