import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPolicy ,updatePolicy } from '../services/policyService';


export const fetchPolicy = createAsyncThunk('policy/fetchPolicy', async () => {
  try {
    const response = await getPolicy();
    return response;
  } catch (error) {
    throw new Error('Error fetching policy: ' + error.message);
  }
});

export const updatedPolicy = createAsyncThunk('policy/updatePolicy', async ({ id, SETTING_ID, data }) => {
  try {
    const response = await updatePolicy(id, SETTING_ID, data);
    return response;
  } catch (error) {
    throw new Error('Error updating policy: ' + error.message);
  }
});


  const policySlice = createSlice({
  name: 'policy',
  initialState: {
    policy: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers:(builder) => {
    builder
    .addCase(fetchPolicy.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchPolicy.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.policy = action.payload.settings;
    })
    .addCase(fetchPolicy.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(updatedPolicy.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(updatedPolicy.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.policy = action.payload.setting;
    })
    .addCase(updatedPolicy.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
},
});

export default policySlice.reducer;