import React , { memo } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo.svg'

const SidebarLogo = () => {
  return(
    <div className="gx-layout-sider-header">
    <Link to="/" >
      <div className="gx-site-logo">
        <img alt="logo2" src={logo}/>
      </div>
    </Link>
  </div>
  ) 

}



export default memo(SidebarLogo)