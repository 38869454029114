import axios from  "axios";
import { authHeader } from "../../helper/Auth";

const url = process.env.REACT_APP_BASE_URL


export const getComments = async () => {
    try {
      const response = await axios.get(`${url}/api/getcomments`,{
        headers: authHeader()
      });
      return response.data;
    } catch (error) {
      throw new Error('Error fetching comments',error);
    }
};
export const getComment = async (id) => {
  try {
    const response = await axios.get(`${url}/api/getcomment/${id}`,{
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching comments',error);
  }
};
export const editComment = async (id , updatedData) => {
  try {
    const response = await axios.put(`${url}/api/editcomment/${id}`, updatedData,{
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching comments',error);
  }
};

