import React, { useEffect, useState } from 'react';
import { fetchPolicy } from '../../redux/slices/policySlice';
import { useSelector, useDispatch } from 'react-redux';

const PolicyView = () => {
  const dispatch = useDispatch();
  const [textValue, setTextValue] = useState('');

  const getData = async () => {
    try {
      const docData = await dispatch(fetchPolicy());
      console.log(docData);
      const dataObj = docData.payload.settings[0];
      setTextValue(dataObj.value);
    } catch (error) {
      console.error('Error fetching policy:', error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <main className='public-view'>
      <div className='container pt-5'>
        <div className='row'>
          <div className='col-md-8 m-auto'>
            <div
              className='policy-content'
              style={{ maxHeight: '700px', overflowY: 'auto', overflowX: 'hidden' }}
              dangerouslySetInnerHTML={{ __html: textValue }}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default PolicyView;
