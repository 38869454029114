import React from 'react'
import {Layout} from "antd";
import AppSidebar from './AppSidebar'
import Topbar from './Topbar'

const {Content, Footer} = Layout;

const currentYear = new Date().getFullYear().toString().slice(2);


const footerText = `© 2021-${currentYear} Sudi Etuz Inc.`;
// "© 2021-\(Common.shared.getCurrentYear()) Sudi Etuz Inc."

const AppLayout = ({children}) => {
  return(
    <Layout className={`gx-app-layout`}>
      <AppSidebar />
      <Layout>
      <Topbar />
      <Content className="gx-layout-content">
        <div className="gx-main-content-wrapper">
          {children}
        </div>
        <Footer>
          <div className="gx-layout-footer-content">
            {footerText}
          </div>
        </Footer>
      </Content>
      </Layout>
    </Layout>
  )
}

export default AppLayout