import React, { useState } from "react";
import { Card, Form, Select, DatePicker, Input, Button } from "antd";
import { NotificationManager } from "react-notifications";
import { updatePassword  } from '../../redux/slices/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 8 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 16,
    },
  },
};



const ChangePassword = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [periodType, setPeriodType] = useState("date");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const id = sessionStorage.getItem('id');

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const { oldPassword, newPassword } = values;
      const apiRes = await dispatch(updatePassword({ id, newPassword, oldPassword }));
      
      if (apiRes.payload.success === true) {
        NotificationManager.success(apiRes.payload.msg);
        form.resetFields();
      } else {
        NotificationManager.error(apiRes.payload.msg); // Show error message from API response 
      }

    } catch (error) {
      console.error('Error:', error);
      NotificationManager.error("Error");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (formData) => {};

  return (
    <div style={{ textAlign: "-webkit-center"}}>
    <Card className="gx-card h-50 w-50 align-content-center" title="Change Password">
      <Form
        {...formItemLayout}
        scrollToFirstError
        name="basic"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Old Passowrd"
          name="oldPassword"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input.Password visibilityToggle ={true} />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input.Password visibilityToggle ={true} />
        </Form.Item>
        <Form.Item
          {...tailFormItemLayout}
          className="d-flex justify-content-end mt-5"
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
    </div>
  );
};

export default ChangePassword;
