import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getQuestions , getQuestionbyId , updateQuestion } from '../services/questionService';


export const fetchquestions = createAsyncThunk('questions/fetchQuestions', async () => {
  try {
    const response = await getQuestions();
    return response;
  } catch (error) {
    throw new Error('Error fetching questions: ' + error.message);
  }
});

export const fetchquestionbyId = createAsyncThunk('questions/fetchQuestionbyId', async (id) => {
  try {
    const response = await getQuestionbyId(id);
    return response;
  } catch (error) {
    throw new Error('Error fetching question by ID: ' + error.message);
  }
});

export const UpdateQuestion = createAsyncThunk('questions/updateQuestion', async ({ id, updatedData }) => {
  try {
    const response = await updateQuestion(id, updatedData);
    return response;
  } catch (error) {
    throw new Error('Error updating question: ' + error.message);
  }
});


  const questionSlice = createSlice({
  name: 'question',
  initialState: {
    questions: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers:(builder) => {
    builder
    .addCase(fetchquestions.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchquestions.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.questions = action.payload.questions;
    })
    .addCase(fetchquestions.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(fetchquestionbyId.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchquestionbyId.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.questions = action.payload;
    })
    .addCase(fetchquestionbyId.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(UpdateQuestion.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(UpdateQuestion.fulfilled, (state, action) => {
      state.status = 'succeeded';
      // console.log(action)
      state.questions = action.payload;
    })
    .addCase(UpdateQuestion.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
},
});

export default questionSlice.reducer;