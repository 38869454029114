import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCompability , createCompability ,updateCompability } from '../services/compabilityService';


export const fetchCompabilties = createAsyncThunk('compabilities/fetchCompabilties', async (lang) => {
  try {
    const response = await getCompability(lang);
    return response;
  } catch (error) {
    throw new Error('Error fetching compatibilities: ' + error.message);
  }
});

export const addCompatibilties = createAsyncThunk('compabilities/addCompatibilties', async ({ data }) => {
  try {
    const response = await createCompability(data);
    return response;
  } catch (error) {
    throw new Error('Error adding compatibility: ' + error.message);
  }
});

export const editCompability = createAsyncThunk('compabilities/editCompability', async ({ lang,key, data }) => {
  try {
    const response = await updateCompability(lang,key,data);
    return response;
  } catch (error) {
    throw new Error('Error editing compatibility: ' + error.message);
  }
});



  const compabilitySlice = createSlice({
  name: 'compability',
  initialState: {
    compabilities: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    emptyCollection: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.compabilities = []
    },
  },
  extraReducers:(builder) => {
    builder
    .addCase(fetchCompabilties.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchCompabilties.fulfilled, (state, action) => {
      state.status = 'succeeded';
      
      state.compabilities = action.payload.compabilities;
    })
    .addCase(fetchCompabilties.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(addCompatibilties.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(addCompatibilties.fulfilled, (state, action) => {
      state.status = 'succeeded';
      
      state.compabilities = action.payload.compability;
    })
    .addCase(addCompatibilties.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(editCompability.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(editCompability.fulfilled, (state, action) => {
      state.status = 'succeeded';
      
      state.compabilities = action.payload.compabilities;
    })
    .addCase(editCompability.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
},
});

export const { emptyCollection } = compabilitySlice.actions
export default compabilitySlice.reducer;