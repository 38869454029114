import React, { useState } from "react";
import { Card, Form, Select, DatePicker, Input, Button, Spin } from "antd";
import { lang, horoscopesOptions, dateType } from "../../helper/constant";
// import { doc, setDoc, getDoc, getFirestore } from "firebase/firestore";
import { NotificationManager } from "react-notifications";
import { generateId } from "../../helper/generateId";
import { createvideoCollection } from '../../redux/slices/horoScopeSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 8 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 16,
    },
  },
};

const HoroscopesForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [periodType, setPeriodType] = useState("date");
  // const db = getFirestore();
  const [loading, setLoading] = useState(false);
  const [merge, setMerge] = useState(true);
  const history = useHistory();

  // Custom validator function to check for blank spaces
  const validateText = (_, value) => {
    if (value && value.trim() !== '') {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Text is required'));
  };

  const validateURL = (_, value) => {
    if (value && value.trim() !== '') {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Video URL is required'));
  };

  const periodSelectHandler = (selected) => setPeriodType(selected);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const data = values;
  
      // Omit video_url and datevalue from the data object
      const { datevalue, ...formattedData } = data;
  
      // Format the date
      formattedData.date = moment(formattedData.date).format("MM-DD-YYYY");
  
      // Dispatch the action with formatted data
      const apiRes = await dispatch(createvideoCollection({ data: formattedData }));
      if (apiRes.payload.success === true) {
        NotificationManager.success("Added successfully");
        form.resetFields();
        history.push('/horoscopes/');
      } else {
        NotificationManager.error(apiRes.payload.msg);
      }
    } catch (error) {
      console.error('Error updating question:', error);
      NotificationManager.error("Error adding compatibility");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (formData) => {};

  return (
    <Card className="gx-card" title="New Horoscope">
      <Spin spinning={loading}>
        <Form
          {...formItemLayout}
          scrollToFirstError
          name="basic"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Language"
            name="lang"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select>
              {lang.map(({ value, label }) => (
                <Option value={value} key={label}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Horoscope"
            name="horoscope"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select>
              {horoscopesOptions.map(({ value, label }) => (
                <Option value={value} key={label}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Period"
            name="period"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select onChange={periodSelectHandler}>
              {dateType.map(({ value, label }) => (
                <Option value={value} key={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Date"
            name="date"
            rules={[{ required: true, message: "Required" }]}
          >
            <DatePicker
              picker={periodType}
              format="DD-MM-YYYY"
              className="gx-w-100"
            />
          </Form.Item>
          <Form.Item name="datevalue" noStyle>
            <Input hidden />
          </Form.Item>
          {/* <Form.Item label="Video Url" name="video_url" rules={[{ validator: validateURL }]}>
            <Input />
          </Form.Item> */}
          <Form.Item
            label="Text"
            name="text"
            rules={[{ validator: validateText }]}
          >
            <TextArea showCount />
          </Form.Item>
          <Form.Item
            {...tailFormItemLayout}
            className="d-flex justify-content-end"
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
};

export default HoroscopesForm;
