import React from "react";
import { Route, Switch } from "react-router-dom";
//import { useSelector } from 'react-redux'
import AllRoutes, { AuthRoutes } from "./routes";

const AppRoutes = ({ routes = AllRoutes }) => {
  //TODO
  //const role = useSelector((state) => state.auth.role)
  //routes = routes.filter((route) => route.per?.includes(role))

  let appRoutes = routes.map((route, i) => (
    <Route
      key={i}
      path={route?.path}
      exact={route?.exact}
      render={(props) => <route.component {...props} />}
    />
  ));

  
  return <Switch>{appRoutes}</Switch>;
};

const AppAuthRoutes = ({ routes = AuthRoutes }) => (
  <Switch>
    {routes.map((route, i) => (
      <Route
        key={i}
        path={route?.path}
        exact={route?.exact}
        render={(props) => <route.component {...props} />}
      />
    ))}
  </Switch>
);

export { AppAuthRoutes };

export default AppRoutes;
