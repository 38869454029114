import axios from  "axios";
import { authHeader } from "../../helper/Auth";


const url = process.env.REACT_APP_BASE_URL


export const getVideocollections = async (language, horoscope, period) => {
  try {
    if (!language || !horoscope || !period) {
      throw new Error('Please provide language, horoscope, and period');
    }

    const response = await axios.get(`${url}/api/getvideobyfilter?language=${language}&horoscope=${horoscope}&period=${period}`,{
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching Videocollections:', error);
  }
};

export const createVideocollection = async (data) => {
  try {
    const response = await axios.post(`${url}/api/createvideo` ,data,{
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching Videocollections',error);
  }
};



