import { createSlice } from '@reduxjs/toolkit';

const AuthenticationSlice = createSlice({
  name: 'auth',
  initialState: {
    access: sessionStorage.getItem('token')
  },
  reducers: {
    authenticate: (state, { payload }) => {
      console.log(payload)
      state.access = payload;
    },
    deauthenticate: (state) => {
      state.access = null;
    },
  },
});

export const { authenticate, deauthenticate } = AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;
