import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Form,
  Row,
  Col,
  Select,
  Button,
  Collapse,
  Input,
  Modal,
  Spin
} from "antd";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { lang} from "../../helper/constant";
import { emptyCollection, fetchCompabilties , editCompability} from '../../redux/slices/compabilitySlice';
import { useSelector, useDispatch } from 'react-redux';

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;

const CompatibilityList = () => {  
  const dispatch = useDispatch();
  const {compabilities} = useSelector((state) => state.compability);
  const [data, setData] = useState([]);
  const [language, setLanguage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openPanelKey, setOpenPanelKey] = useState(null);
  const [form] = Form.useForm();

  const SubmitHandler = (lang) =>{
    if (language) {
      setLoading(true);
      setData([]);
      dispatch(fetchCompabilties(lang));
    }
  };

  const getData = async () => {
    let dataArray = [];
  
    if (compabilities && compabilities.length > 0) {
      const innerData = Array.isArray(compabilities[0]) ? compabilities[0] : compabilities;
  
      innerData.forEach((item) => {
        Object.keys(item).forEach((compatibilityKey) => {
          if (compatibilityKey !== 'ID' && compatibilityKey !== '_id'){
            const compatibilityData = item[compatibilityKey];
            dataArray.push({
              name: compatibilityKey ? compatibilityKey : null,
              VIDEO_URL: compatibilityData.VIDEO_URL ? compatibilityData.VIDEO_URL : null,
              VIDEO_TEXT: compatibilityData.VIDEO_TEXT ? compatibilityData.VIDEO_TEXT : null,
            });
          }
        });
      });
  
      // Sort the dataArray alphabetically based on the 'name' property
      dataArray.sort((a,b) => (a.name > b.name) ? 1 : -1);
  
      setLoading(false);
      setData(dataArray);
    } else {
      // Handle the case when compabilities is empty
    }
  };

  const langSelectHandler = (selected) => setLanguage(selected);

  const onChanageFinish = async (formData) => {
    setLoading(true);
    try {
      formData = formData.items;
      formData.forEach((item) => {
        // Only update the data for the currently opened panel
        if (item.name === openPanelKey) {
          const editedData = {
            VIDEO_TEXT: item.VIDEO_TEXT
          };
          const apiRes = dispatch(editCompability({ lang:language,key: item.name, data: editedData }));
          // console.log(apiRes)
          NotificationManager.success("Updated successfully");
        }
      });
    } catch (error) {
      console.error("Error:", error);
      NotificationManager.error("Error Updating compatibility");
    }finally {
      setLoading(false);
    }
  };

  const playVideoHandler = (url) => setIsModalVisible(url)

  const handleReset = () => {
    form.resetFields();
    setData([]);
    window.location.reload();
  };

  if (compabilities) {
    // Construct initial values object
    const initialValues = {};
    // console.log(data)
    data.forEach(item => {
      // console.log(item)
      // console.log(item)   
      initialValues[item.name] = {
        VIDEO_URL: item.VIDEO_URL,
        VIDEO_TEXT: item.VIDEO_TEXT,
      };
    });
    // console.log("iiiiiiiiiiiiiiiiiii",data)
  // console.log(data)
    // Set initial values for the form
    form.setFieldsValue({
      items: data
    });
  }

  // console.log(compabilities)

  useEffect(() => {
    if (compabilities) {
      getData();
    }
  }, [compabilities]);

  useEffect(() => {
    setData([]);
  }, []);

  
  
  return (
    <Card
      className="gx-card"
      title={
        <div className="d-flex justify-content-between">
          <p>COMPATIBILITY</p>
          {/* <Link to={`add`} className="ant-btn ant-btn-primary">
            <p>New</p>
          </Link> */}
        </div>
      }
    >
      {/* <Form  onFinish={SubmitHandler}> */}
        <Row>
          <Col lg={6} className="mx-3">
            <Form.Item name="lang" label="Language">
              <Select defaultValue=""  onChange={langSelectHandler}>
              <Option value="" disabled>
                Select Language
                </Option>
                {lang.map(({ value, label }) => (
                  <Option value={value} key={label}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Button type="primary" onClick={()=>SubmitHandler(language)}>
              Search
            </Button>
            <Button type="primary" onClick={handleReset}>
              Reset
            </Button>
          </Col>
        </Row>
      {/* </Form> */} 
      <Spin spinning={loading}>
        <Form form={form} onFinish={onChanageFinish}>
          {data.length > 0 ? (
            <Form.List name="items" initialValue={data}>
              {(fields, { add, remove }) => (
                <Collapse
                  accordion
                  onChange={(key) => setOpenPanelKey(key)} // Update the openPanelKey when a panel is opened
                >
                  {fields.map((field) => (
                    <Panel
                      key={data[field.name]?data[field.name].name:null} // Use a unique key for each panel
                      header={data[field.name] ? data[field.name].name : null}
                    >
                      <div className="d-flex py-3">
                        <label style={{ flex: '1 1 10%'}}>VIDEO URL: </label>
                        <div className="w-100 overflow-hidden">
                          <div onClick={() => playVideoHandler(data[field.name]?data[field.name].VIDEO_URL:null)}>
                            {data[field.name]?data[field.name].VIDEO_URL:null}
                          </div>
                        </div>
                      </div>
                      <Form.Item
                        {...field}
                        label={"VIDEO TEXT"}
                        name={[field.name, "VIDEO_TEXT"]}
                        fieldKey={[field.fieldKey, "VIDEO_TEXT"]}
                        className="px-3"
                      >
                        <TextArea showCount rows={8}/>
                      </Form.Item>
                    </Panel>
                  ))}
                </Collapse>
              )}
            </Form.List>
          ) : null}
          {data.length > 0 && (
            <div className="d-flex justify-content-end mt-3">
              <Button htmlType="submit" type="primary">
                Submit
              </Button>
            </div>
          )}
        </Form>
      </Spin>
      <Modal
        visible={isModalVisible}
        onOk={() => setIsModalVisible(null)}
        onCancel={() => setIsModalVisible(null)}
        bodyStyle={{ width:'500px' }}
        destroyOnClose={true}
      >
        <video height="700" controls autoPlay style={{ display:'block' , margin: 'auto' }}>
          <source src={isModalVisible} type="video/mp4" />
        </video>
      </Modal>
    </Card>
  );
};

export default CompatibilityList;
