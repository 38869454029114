export const  Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const horoscopesOptions = [
  { value: "Aries", label: "Aries" },
  { value: "Taurus", label: "Taurus" },
  { value: "Gemini", label: "Gemini" },
  { value: "Cancer", label: "Cancer" },
  { value: "Leo", label: "Leo" },
  { value: "Virgo", label: "Virgo" },
  { value: "Libra", label: "Libra" },
  { value: "Scorpio", label: "Scorpio" },
  { value: "Saggitarius", label: "Sagittarius" },
  { value: "Capricon", label: "Capricorn" },
  { value: "Aquarius", label: "Aquarius" },
  { value: "Pisces", label: "Pisces" },
];

export const lang  = [
  { value: 'EN' , label: 'English'},
  { value: 'TR' , label: 'Turkish'}
]
 
export  const dateType = [
  { value: "DAILY", label: "DAILY" },
  { value: "WEEKLY", label: "WEEKLY" },
  { value: "MONTHLY", label: "MONTHLY" },
];

export const userType = [
  {value: '0' , label: 'User'},
  {value: '1' , label: 'Astro'},
  {value: '2' , label: 'Admin'}
]