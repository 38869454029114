import React, { useState } from "react";
import { Card, Form, Input , Button, Select } from "antd";
import { NotificationManager } from "react-notifications";
import { useDispatch } from 'react-redux';
import { createNotification } from '../../redux/slices/notificationSlice';
import { useHistory } from "react-router-dom";

const { Item } = Form;
const { TextArea } = Input;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 8 },
  },
};

const AddNotification = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();



  const validateTitle = (_, value) => {
    if (value && value.trim() !== '') {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Notification Title is required'));
  };
  const validateMsg = (_, value) => {
    if (value && value.trim() !== '') {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Notification Message is required'));
  };
  const validateURL = (_, value) => {
    if (value && value.trim() !== '') {
      return Promise.resolve();
    }
    return Promise.reject(new Error('URL is required'));
  };
  const validateMessage = (_, value) => {
    if (value && value.trim() !== '') {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Message is required'));
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const formData = values;
      const apiRes = await dispatch(createNotification({ data: formData }));
      if(apiRes.payload.success === true){
      NotificationManager.success("Notification added successfully");
      history.push('/notification/');
      form.resetFields();
      }else{
        NotificationManager.error(apiRes.payload.msg);
      }
    } catch (error) {
      console.error('Error adding notification:', error);
      NotificationManager.error("Error adding notification");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="gx-card" title="New Notification">
      <Form
        {...formItemLayout}
        scrollToFirstError
        form={form}
        name=""
        onFinish={onFinish}
      >
        <Item name="type" label="Type" rules={[{ required: true, message: "Required" }]}>
          <Select>
            {/* <Option value="answer">Answer</Option> */}
            <Option value="notification">Notification</Option>
          </Select>
        </Item>
        <Item name="notificationheader" label="Notification Title" rules={[{ required: true,validator: validateTitle }]}>
          <Input />
        </Item>
        <Item name="notificationmessage" label="Notification Message" rules={[{ required: true,validator: validateMsg }]}>
          <Input />
        </Item>
        {/* <Item name="url" label="URL" rules={[{ validator: validateURL }]}>
          <Input />
        </Item>
        <Item name="message" label="Message" rules={[{ validator: validateMessage }]}>
          <TextArea rows={6} />
        </Item> */}
        <Item className="d-flex justify-content-end">
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Item>
      </Form>
    </Card>
  );
};

export default AddNotification;
