import React, { useEffect, useState } from "react";
import { Card, Form, Button, Spin } from "antd";
import CKEditor from "react-ckeditor-component";
import { NotificationManager } from "react-notifications";
import { fetchPolicy, updatedPolicy } from '../../redux/slices/policySlice';
import { useSelector, useDispatch } from 'react-redux';

const { Item } = Form;

const Policy = () => {
  const dispatch = useDispatch();
  const { policy } = useSelector((state) => state.policy);
  const [form] = Form.useForm();
  const [textValue, setTextValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false); // State to manage loading

  useEffect(() => {
    const getData = async () => {
      setSpinner(true);
      try {
        await dispatch(fetchPolicy());
      } catch (error) {
        console.error("Failed to fetch policy data", error);
      }
      finally {
        setSpinner(false); // Set loading back to false when fetching is completed
      }
    };
    getData();
  }, [dispatch]);

  useEffect(() => {
    if (policy && policy.length > 0) {
      setTextValue(policy[0].value);
    }
  }, [policy]);

  const updatePolicy = async () => {
    try {
      const firstPolicy = policy && policy.length > 0 ? policy[0] : null;

      if (firstPolicy) {
        const { _id, SETTING_ID } = firstPolicy;
        const updatedData = { value: textValue };

        // Dispatch the updatePolicy action
        const apiRes = await dispatch(updatedPolicy({ id: _id || SETTING_ID, data: updatedData }));
        if (apiRes.payload.success === true) {
          NotificationManager.success("Policy value updated successfully");
          await dispatch(fetchPolicy());
        } else {
          NotificationManager.error(apiRes.payload.msg);
        }
      } else {
        NotificationManager.error("No policy data available");
      }
    } catch (error) {
      console.error("Failed to update policy value:", error);
      NotificationManager.error("Failed to update policy value");
    } finally {
      setLoading(false);
    }
  };

  const onFinish = () => {
    setLoading(true);
    updatePolicy();
  };

  return (
    <Card className="gx-card" title="Policy">
      <Spin spinning={spinner}> {/* Display Spin component while loading */}
        <Form form={form} onFinish={onFinish}>
          <Item name="value">
            <CKEditor
              content={textValue}
              events={{
                change: (evt, editor) => setTextValue(evt.editor.getData()),
              }}
              readOnly={spinner} /* Make CKEditor read-only while loading */
            />
          </Item>
          <div className="d-flex justify-content-end">
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </div>
        </Form>
      </Spin>
    </Card>
  );
};

export default Policy;
