import axios from  "axios";
import { authHeader } from "../../helper/Auth";

const url = process.env.REACT_APP_BASE_URL


export const getAccounts = async () => {
    try {
      const response = await axios.get(`${url}/api/getaccounts`,{
        headers: authHeader()
      });
      return response.data;
    } catch (error) {
      throw new Error('Error fetching accounts',error);
    }
};
export const getAccount = async (id) => {
  try {
    const response = await axios.get(`${url}/api/getaccount/${id}`,{
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching accounts',error);
  }
};
export const DeleteAccount = async (id) => {
  try {
    const response = await axios.delete(`${url}/api/deleteaccount/${id}`,{
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching accounts',error);
  }
};

