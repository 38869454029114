import react , { useEffect, useState } from 'react'
import { useParams , useHistory } from 'react-router-dom'

import { NotificationManager } from "react-notifications";
import { Card ,Form , Input , Button } from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserbyId , UpdateUser } from '../../redux/slices/userSlice';

const { Item } = Form
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 8 },
  },
};

const UsersForm = () => {
  const { id } = useParams()
  const [form] = Form.useForm();
  const history = useHistory()
  const dispatch = useDispatch();
  const {users} = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  
  useEffect(() => {
    const fetchData = async (id) => {
      try {
        await dispatch(fetchUserbyId(id));
        if (!users) {
          NotificationManager("Record Not Found");
        }
      } catch (error) {
        console.error('Error fetching question:', error);
      }
    };
    fetchData(id)
    
  }, [dispatch, id]);

  if (users) {
  form.setFieldsValue({
    FULL_NAME: users?users.FULL_NAME :null, 
    USER_NAME: users?users.USER_NAME:null,
  });
}


  const onFinish = async (values) => {
    // console.log(values)
    try {
      setLoading(true);
      const updatedData = values;
      const apiRes = await dispatch(UpdateUser({id, updatedData:updatedData}));
      if(apiRes.payload.success === true){
      // console.log(updatedData)
      NotificationManager.success("User updated successfully");
      dispatch(fetchUserbyId(id));
      history.push('/users');
      form.resetFields();
      }else{
        NotificationManager.error(apiRes.payload.msg);
      }
    } catch (error) {
      console.error('Error updating question:', error);
      NotificationManager.error("Error updating question");
    } finally {
      setLoading(false);
    }
  };

// console.log(users)
  
  return(
    <Card className="gx-card" title="Update User">
      <Form {...formItemLayout} form={form} initialValues={{
         FULL_NAME: users?users.FULL_NAME :null, 
         USER_NAME: users?users.USER_NAME:null, 
      }}
      onFinish={onFinish}>
        <Item name="FULL_NAME"  label="Full Name">
            <Input />
        </Item>
        <Item name="USER_NAME" label="User Name">
          <Input />
        </Item>
        <Item className="d-flex justify-content-end">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Item>
      </Form>
    </Card>
  ) 
}

export default UsersForm