import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCharts , updateCharts} from '../services/chartService';


export const fetchCharts = createAsyncThunk('charts/fetchCharts', async (lang ) => {
  try {
    const response = await getCharts(lang);
    return response;
  } catch (error) {
    throw new Error('Error fetching compatibilities: ' + error.message);
  }
});
export const editCharts = createAsyncThunk('charts/editCharts', async ({lang, key, data }) => {
  try {
    const response = await updateCharts(lang,key,data);
    return response;
  } catch (error) {
    throw new Error('Error editing compatibility: ' + error.message);
  }
});






const chartSlice = createSlice({
    name: 'chart',
    initialState: {
      charts: [],
      status: 'idle',
      error: null,
    },
    reducers: {
      emptyCollection: (state) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.charts = []
      },
    },
    extraReducers:(builder) => {
      builder
      .addCase(fetchCharts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCharts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // console.log(action)
        state.charts = action.payload.charts;
      })
      .addCase(fetchCharts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editCharts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editCharts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // console.log(action)
        state.charts = action.payload.charts;
      })
      .addCase(editCharts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
    },
});

export const { emptyCollection } = chartSlice.actions
export default chartSlice.reducer;