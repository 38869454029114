import axios from  "axios";
import { authHeader } from "../../helper/Auth";


const url = process.env.REACT_APP_BASE_URL


export const getCharts = async (language) => {
    try {
      const response = await axios.get(`${url}/api/getchartsbylang?ID=${language}`,{
        headers: authHeader()
      });
      // console.log(response.data)
      return response.data;
    } catch (error) {
      throw new Error(error,'Error fetching charts',error);
    }
};


export const updateCharts = async (lang,key,data) => {
  try {
    const response = await axios.put(`${url}/api/updatechart/${lang}/${key}`,data,{
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw new Error(error,'Error fetching charts',error);
  }
};