import { configureStore } from '@reduxjs/toolkit'

import AuthReducer from './AuthReducer'
import userReducer from './slices/userSlice'
import questionReducer from './slices/questionSlice'
import videoCollectionReducer from './slices/horoScopeSlice'
import compabilityReducer from './slices/compabilitySlice'
import policyReducer from './slices/policySlice'
import commentReducer from './slices/commentSlice'
import accountReducer from './slices/accountSlice'
import notificationReducer from './slices/notificationSlice'
import chartReducer from './slices/chartSlice'


const reducer = {
    auth: AuthReducer,
    user: userReducer,
    question: questionReducer,
    videoCollection : videoCollectionReducer,
    compability : compabilityReducer,
    policy : policyReducer, 
    comment : commentReducer, 
    account : accountReducer, 
    notification : notificationReducer, 
    chart : chartReducer, 
}


const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;
