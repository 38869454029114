import Signin from "../pages/Auth/Singin";
import NotFound from "../pages/NotFound";
import HoroscopesList from "../pages/horoscopes/HoroscopesList";
import HoroscopesForm from "../pages/horoscopes/HoroscopsForm";
import UsersList from "../pages/Users/UsersList";
import UsersForm from "../pages/Users/UsersForm";
import CompatibilityList from "../pages/Compatibility/CompatibilityList";
import CompatibilityForm from "../pages/Compatibility/CompatibilityForm";
import QuestionList from "../pages/Questions/QuestionsList";
import QuestionForm from "../pages/Questions/QuestionForm";
import PolicyForm from "../pages/Policy";
import PolicyView from "../pages/Policy/View";
import CommentList from "../pages/Comments/CommentList";
import CommentForm from "../pages/Comments/CommentForm";


//# Icons
import {
  CompassOutlined,
  UserOutlined,
  SketchOutlined,
  QuestionCircleOutlined,
  HighlightOutlined,
  FileTextOutlined,
  UsergroupDeleteOutlined,
  BellOutlined,
  AreaChartOutlined,
  GlobalOutlined,
  TeamOutlined,
  CommentOutlined,
  SafetyCertificateOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import Account from "../pages/Account_Delete_Reason/Account";
import AccountForm from "../pages/Account_Delete_Reason/AccountForm";
import SendPushNotificationForm from "../pages/Notification/SendNotification";
import ResetPassword from "../pages/Auth/ResetPassword";
import Notification from "../pages/Notification/Notification";
import addNotification from "../pages/Notification/addNotification";
import ChangePassword from "../pages/Auth/changePassword";
import charts from "../pages/Charts/charts.jsx";





const errorRoutes = [
  // {
  //   path: "/error",
  //   component: NotFound,
  //   exact: true,
  // },
];

const AuthRoutes = [
  {
    path: "/",
    component: Signin,
    exact: true,
  },
  {
    path: "/reset/:resetToken",
    component: ResetPassword,
    exact: true,
  },
  {
    path: "/viewpolicy",
    component: PolicyView,
    exact: true,
  },
  {
    path: "/error",
    component: NotFound,
    exact: true,
  },
];

const routes = [
  {
    title: "Change Password",
    path: "/changepassword/",
    icon: <SketchOutlined />,
    component: ChangePassword,
    nav: false,
    exact: true,
    per: ["admin", "associate"],
  },
  {
    title: "Horoscopes",
    path: "/horoscopes/",
    icon: <GlobalOutlined />,
    component: HoroscopesList,
    nav: true,
    exact: true,
    per: ["admin", "associate"],
  },
  {
    title: "Add Horoscope",
    path: "/horoscopes/add",
    component: HoroscopesForm,
    exact: true,
    per: ["admin", "associate"],
  },
  {
    title: "update User",
    path: "/update/:id",
    component: UsersForm,
    exact: true,
    per: ["admin", "associate"],
  },
  {
    title: "Charts",
    path: "/charts/",
    icon: <AreaChartOutlined />,
    component: charts,
    nav: true,
    exact: true,
    per: ["admin", "associate"],
  },
  {
    title: "Compatibility",
    path: "/compatibility/",
    icon: <TeamOutlined />,
    component: CompatibilityList,
    nav: true,
    exact: true,
    per: ["admin", "associate"],
  },
  {
    title: "Users",
    path: "/users",
    icon: <UserOutlined />,
    component: UsersList,
    nav: true,
    exact: true,
    per: ["admin", "associate"],
  },
  {
    title: "Notifications",
    path: "/notification/",
    icon: <BellOutlined />,
    component: Notification,
    nav: true,
    exact: true,
    per: ["admin", "associate"],
  }, 
  {
    title: "Compatibility",
    path: "/compatibility/add",
    icon: <CompassOutlined />,
    component: CompatibilityForm,
    exact: true,
    per: ["admin", "associate"],
  },
  // {
  //   title: "Questions",
  //   path: "/questions/",
  //   icon: <QuestionCircleOutlined />,
  //   component: QuestionList,
  //   nav: true,
  //   exact: true,
  //   per: ["admin", "associate"],
  // },
  // {
  //   title: "Questions",
  //   path: "/questions/:id",
  //   icon: <QuestionCircleOutlined />,
  //   component: QuestionForm,
  //   nav: false,
  //   exact: true,
  //   per: ["admin", "associate"],
  // },
  {
    title: "Comments",
    path: "/comments/",
    icon: <CommentOutlined />,
    component: CommentList,
    nav: true,
    exact: true,
    per: ["admin", "associate"],
  },
  {
    title: "Comments",
    path: "/comments/:id",
    icon: <QuestionCircleOutlined />,
    component: CommentForm,
    nav: false,
    exact: true,
    per: ["admin", "associate"],
  },
  {
    title: "Policy",
    path: "/editpolicy",
    icon: <SafetyCertificateOutlined />,
    component: PolicyForm,
    nav: true,
    exact: true,
    per: ["admin", "associate"],
  },
  {
    title: "Deleted Accounts",
    path: "/accounts/",
    icon: <DeleteOutlined />,
    component: Account,
    nav: true,
    exact: true,
    per: ["admin", "associate"],
  },
  {
    title: "Comments",
    path: "/accounts/:id",
    icon: <QuestionCircleOutlined />,
    component: AccountForm,
    nav: false,
    exact: true,
    per: ["admin", "associate"],
  },
  {
    title: "Notification",
    path: "/notification/add",
    icon: <BellOutlined />,
    component: addNotification,
    nav: false,
    exact: true,
    per: ["admin", "associate"],
  },
 
];

export { errorRoutes, AuthRoutes };

export default routes;
