import React, { useState , useEffect } from "react";
import moment from "moment";
// import { getDocs , collection , getFirestore } from "firebase/firestore";/import { fetchUsers } from '../../redux/slices/userSlice';
import { fetchNotifications,SendNotification,DeleteNotification } from '../../redux/slices/notificationSlice';
import { Card, Table ,Button , Modal , Spin} from "antd";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from 'react-redux';
import { DeleteOutlined  } from "@ant-design/icons";


const Notification = () => {
  const dispatch = useDispatch();
//   const {questions} = useSelector((state) => state.question);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false); // State to manage loading

  const getData = async () =>  {
    setLoading(true); // Set loading to true when fetching starts
    try {
    const dataArray = []
    const querySnapshot = await dispatch(fetchNotifications());
    querySnapshot.payload?querySnapshot.payload.notification.forEach( doc => {
      dataArray.push({id: doc._id ,title: doc?.notificationHeader , message: doc?.notificationMessage , type: doc?.type })
    }):null
    setData(dataArray.reverse());
  } catch (error) {
    console.error("Error fetching users:", error);
  } finally {
    setLoading(false); // Set loading back to false when fetching is completed
  }
  }

  const handleSend = async (id) =>{
    try {
      Modal.confirm({
        title: 'Notification',
        content: 'Are you sure you want to send this notification?',
        onOk() {
          // Call your delete API or logic here
          dispatch(SendNotification(id));
          NotificationManager.success("Notification sent successfully");
        },
        onCancel() {
          console.log('Cancel');
        },
      });
}catch (error) {
    console.error('Error sending notification:', error);
    NotificationManager.error("Error sending notification");
  }
}



const handleDelete = async (id) =>{
  try {
    Modal.confirm({
      title: 'Delete',
      content: 'Are you sure you want to delete this notification?',
      onOk() {
        // Call your delete API or logic here
        dispatch(DeleteNotification(id))
          .then(() => {
            NotificationManager.success("Notification deleted successfully");
            getData(); // Fetch notifications again after deleting one
          })
          .catch((error) => {
            console.error('Error deleting notification:', error);
            NotificationManager.error("Error deleting notification");
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  } catch (error) {
    console.error('Error deleting notification:', error);
    NotificationManager.error("Error deleting notification");
  }
}


  useEffect(() => {
    getData();
  }, []);

  const columns = [
    { title: "Title", key: "title", dataIndex: "title" },
    { title: "Message", key: "message", dataIndex: "message"},
    { title: "Type", key: "type", dataIndex: "type" },
    {
      title: "Action",
      key: "edit",
      render: (art) => (
        <div>
          {/* <Link to={`${art.id}`}>
            <Button
              type="link"
              icon={<EyeOutlined />}
              style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
            ></Button>
          </Link> */}
          <Button
            type="link"
            className="ms-2"
            onClick={() => handleSend(art.id)}
            style={{ backgroundColor: "black" , color:"white" }}
          >
            Send
          </Button>
          
            {/* <Button
              type="link"
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(art.id)}
              style={{ backgroundColor: "black" , color:"white"}}
            ></Button> */}
    
        </div>
      ),
    },
  ];
  
  return     <Card
  className="gx-card"
  title={
    <div className="d-flex justify-content-between">
      <p>Notifications</p>
      <Link to={`add`} className="ant-btn ant-btn-primary">
        <p>New</p>
      </Link>
    </div>
  }
>
<Spin spinning={loading}> {/* Display Spin component while loading */}
  <Table dataSource={data?data:null} columns={columns} rowKey="_id"  />
  </Spin>
</Card>
}


export default Notification