import { auth } from "../firebaseConfig";
import store from "../redux";
import { deauthenticate } from "../redux/AuthReducer";
import { NotificationManager } from "react-notifications";
import axios from 'axios';


const url = process.env.REACT_APP_BASE_URL;

export const logInByPassword = async (email, password) => {
  try {
    const res = await axios.post(`${url}/api/login`, { email, password });
    console.log(res);
    sessionStorage.setItem('token', res.data.token);
    sessionStorage.setItem('user', res.data.user.EMAIL);
    sessionStorage.setItem('profile', res.data.user.PHOTO);
    sessionStorage.setItem('id', res.data.user._id);
    return res;
  } catch (error) {
    throw error.response.data.message;
  }
};


export const logOut = (token) => {
  axios.post(`${url}/api/logout`,{token})
    .then(() => {
      store.dispatch(deauthenticate());
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("profile");
      sessionStorage.removeItem("id");
    })
    .catch(e => NotificationManager.error(e.message));
};
