import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import "./styles/style.min.css";
import React from "react";
import AppLayout from "./components/layout";
import AppRoutes, { AppAuthRoutes } from "./routes/index";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";


import { NotificationContainer } from "react-notifications";
import ResetPassword from "./pages/Auth/ResetPassword";

function App() {
  const history = useHistory();
  const token = useSelector((state) => state.auth.access);
  //token ? history.push(history.location.path) : history.push("/sign-in");
  
  return (
    <>
      {token ? (
        <AppLayout>
          <AppRoutes />
          <NotificationContainer />
        </AppLayout>
      ) : (
        <>
          <AppAuthRoutes />
          <NotificationContainer />
        </>
      )}
    </>
  );
}

export default App;
