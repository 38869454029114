import React, { useState , useEffect } from "react";
import moment from "moment";
// import { getDocs , collection , getFirestore } from "firebase/firestore";/import { fetchUsers } from '../../redux/slices/userSlice';
import { fetchComments } from '../../redux/slices/commentSlice';
import { Card, Table ,Button,Spin } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { EditOutlined } from "@ant-design/icons";


const CommentList = () => {
  const dispatch = useDispatch();
  const {questions} = useSelector((state) => state.question);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false); // State to manage loading

  const getData = async () =>  {
    setLoading(true); // Set loading to true when fetching starts
    try {
    const dataArray = []
    const querySnapshot = await dispatch(fetchComments());
    querySnapshot.payload?querySnapshot.payload.commentsWithUserNames.forEach( doc => {
      const formattedDate = moment(doc?.DATE, "MMMM DD, YYYY [at] h:mm:ss A [UTC]Z").format('DD/MM/YYYY,HH:mm');
      dataArray.push({id: doc._id ,rate: doc?.RATE , date: formattedDate , user: doc?.username , comment: doc?.COMMENT})
    }):null
    setData(dataArray.reverse())
  } catch (error) {
    console.error("Error fetching users:", error);
  } finally {
    setLoading(false); // Set loading back to false when fetching is completed
  }
  }

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    { title: "Rate", key: "rate", dataIndex: "rate"},
    { title: "Date", key: "date", dataIndex: "date" },
    { title: "User", key: "user", dataIndex: "user" },
    { title: "Comments", key: "comment", dataIndex: "comment" },
    {
      title: "Action",
      key: "edit",
      render: (art) => (
        <div>
          <Link to={`${art.id}`}>
           <Button type="link" icon={<EditOutlined />} style={{ backgroundColor: "black" , color:"white" }}>
          </Button>
          </Link>
          
        </div>
      ),
    },
  ];
  return     <Card
  className="gx-card"
  title={
    <div className="d-flex justify-content-between">
      <p>Comments</p>
      {/*<Link to={`add`} className="ant-btn ant-btn-primary">
        <p>New</p>
      </Link>*/}
    </div>
  }
>
<Spin spinning={loading}> {/* Display Spin component while loading */}
  <Table dataSource={data?data:null} columns={columns} rowKey="_id"  />
  </Spin>
</Card>
}


export default CommentList