import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getNotifications , addNotification , sendNotification , deleteNotification} from '../services/notificationService';



export const fetchNotifications = createAsyncThunk('notifications/fetchNotifications', async () => {
  try {
    const response = await getNotifications();
    return response;
  } catch (error) {
    throw new Error('Error fetching notification' + error.message);
  }
});
export const createNotification = createAsyncThunk('notifications/createNotification', async ({ data }) => {
  try {
    const response = await addNotification(data);
    return response;
  } catch (error) {
    throw new Error('Error creating notification ' + error.message);
  }
});
export const SendNotification = createAsyncThunk('notifications/SendNotification', async (id) => {
  try {
    const response = await sendNotification(id);
    return response;
  } catch (error) {
    throw new Error('Error sending notification ' + error.message);
  }
});
export const DeleteNotification = createAsyncThunk('notifications/deletenotification', async (id) => {
  try {
    const response = await deleteNotification(id);
    return response;
  } catch (error) {
    throw new Error('Error sending notification ' + error.message);
  }
});


  const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    collection: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    removeNotification(state, action) {
      state.notifications = state.notifications.filter(notification => notification.id !== action.payload);
    },
  },
  extraReducers:(builder) => {
    builder
    .addCase(fetchNotifications.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchNotifications.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.notification = action.payload.notification;
    })
    .addCase(fetchNotifications.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(createNotification.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(createNotification.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.notification = action.payload.notification;
    })
    .addCase(createNotification.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(SendNotification.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(SendNotification.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.notification = action.payload.notification;
    })
    .addCase(SendNotification.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(DeleteNotification.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(DeleteNotification.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.notification = action.payload.notification;
    })
    .addCase(DeleteNotification.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
},
});


export const { removeNotification } = notificationSlice.actions;
export default notificationSlice.reducer;