import moment from 'moment'
import { Days } from './constant'

export  const generateId = ({ date ,dateStr} , period ) => {

  let dateId = null
  let dateInfo = {}
  let d = new Date(dateStr)

  if(period === "week"){
    let value = null
    let selectedDate = new Date(date)
    let year = selectedDate.getFullYear()
    let month = selectedDate.getMonth()

    let weeks = getWeeksInMonth(year , month )
    
    value = weeks.map(( week , i ) => {
      let startDate = moment(week.start , 'D-M-YYYY')
      let endDate = moment(week.end , 'D-M-YYYY')
      let selectedDateMom = moment(selectedDate ,'D-M-YYYY')

      if(selectedDateMom.isBetween(startDate ,endDate) || 
          selectedDateMom.format('D-M-YYYY') === endDate.format('D-M-YYYY')){
        return { weekNum: i + 1, rang: week }
      }
    }).filter( Boolean )[0]

    if(value === undefined){
      month -= 1
      let weeks = getWeeksInMonth(year , month)
      value = weeks.map(( week , i ) => {
        let startDate = moment(week.start , 'D-M-YYYY')
        let endDate = moment(week.end , 'D-M-YYYY')
        let selectedDateMom = moment(selectedDate ,'D-M-YYYY')
  
        if(selectedDateMom.isBetween(startDate ,endDate) || 
            selectedDateMom.format('D-M-YYYY') === endDate.format('D-M-YYYY')){
          return { weekNum: i + 1, rang: week }
        }
      }).filter( Boolean )[0]  
    }
    if(value === undefined){
      month += 1
      let weeks = getWeeksInMonth(year , month)
      value = weeks.map(( week , i ) => {
        let startDate = moment(week.start , 'D-M-YYYY')
        let endDate = moment(week.end , 'D-M-YYYY')
        let selectedDateMom = moment(selectedDate ,'D-M-YYYY')
  
        if(selectedDateMom.isBetween(startDate ,endDate) || 
            selectedDateMom.format('D-M-YYYY') === endDate.format('D-M-YYYY')){
          return { weekNum: i + 1, rang: week }
        }
      }).filter( Boolean )[0]
    }

    dateId = `${value.weekNum}-${month + 1}/${year}`
    dateInfo = { WEEK_NO: value.weekNum , RANG: value.rang }

  }else if(period === "month"){
    dateId = `${d.getUTCMonth() + 1}/${d.getUTCFullYear()}`

  }else{
    dateId = `${d.getUTCDate()}/${d.getUTCMonth()+1}/${d.getUTCFullYear()}`
    dateInfo = {DAY: Days[d.getDay()]  }
  }
  return { dateId , dateInfo }
} 



var getWeeksInMonth = (year , month) => {
  let monthStartDate = moment(new Date(year , month , 1 )).clone().startOf('month').format('YYYY-MM-DD')
  let monthEndDate = moment(new Date(year , month , 1 )).clone().endOf('month').format('YYYY-MM-DD')
  
  let weeks = [];
  let startDate = moment(monthStartDate).isoWeekday(8);
  let endDate = moment(monthEndDate).isoWeekday(8)
  
  if (startDate.date() == 8) {
    startDate = startDate.isoWeekday(-6);
  }
  while (startDate.isBefore(endDate)) {
    let startDateWeek = startDate.isoWeekday("Monday").format("D-M-YYYY");
    let endDateWeek = startDate.isoWeekday("Sunday").format("D-M-YYYY");
    startDate.add(7, "days");
    weeks.push({start:`${startDateWeek}` , end:`${endDateWeek}`});
  }
  return weeks;
};


