import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Card, Form, Input, Button } from "antd";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from 'react-redux';
import { resetPass } from "../../redux/slices/userSlice";
import Swal from 'sweetalert2';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const { Item } = Form;
const { TextArea } = Input;

const ResetPassword = () => {
  const { resetToken } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
  }, [resetToken]);

  
  const validatePassword = (_, value) => {
    if (value && value.trim() !== '') {
      return Promise.resolve();
    }
    return Promise.reject(new Error('New Password is required'));
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const updatedData = values;
      const apiRes = await dispatch(resetPass({ resetToken, password: updatedData }));
      // console.log(apiRes);
      if (apiRes.payload.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Password reset successfully, please login',
          showConfirmButton: true,
          // timer: 2500,
        });
        form.resetFields();
      } else {
        Swal.fire({
          icon: 'error',
          title: apiRes.payload,
          showConfirmButton: false,
          timer: 2500,
        });
      }

    } catch (error) {
      console.error('Error reseting password:', error);
      NotificationManager.error("Error reseting password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{textAlign: "-webkit-center" }}>
    <div className="main" style={{ padding: "20px", textAlign: "-webkit-center" , maxWidth:"500px" }}>
      <Card className="gx-card h-100 w-100 align-content-center" title="Reset Password"
        style={{ marginTop: "11rem", textAlign: "center" }}
      >
        <Form
          {...formItemLayout}
          scrollToFirstError
          form={form}
          name=""
          onFinish={onFinish}
          style={{ maxWidth: "400px", width: "100%" }} // Inline style for maximum width

        >
          <Item name="password" label="New Password" rules={[{ required: true,validator: validatePassword }]}>
            <Input.Password visibilityToggle={true} />
          </Item>
          <Item className="d-flex justify-content-center mt-4">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Item>
        </Form>
      </Card>
    </div>
    </div>
  );
};
export default ResetPassword;
