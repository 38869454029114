import React , { useState } from "react";
import {Drawer, Layout} from "antd";

import SidebarContent from "./SidebarContent";


const NAV_STYLE_DRAWER = 'NAV_STYLE_DRAWER';
const NAV_STYLE_MINI_SIDEBAR = 'NAV_STYLE_MINI_SIDEBAR';
const NAV_STYLE_NO_HEADER_MINI_SIDEBAR = 'NAV_STYLE_NO_HEADER_MINI_SIDEBAR';

const {Sider} = Layout;

const Sidebar = () => {
  const [navCollapsed,setNavCollapsed] = useState(false)
  const themeType = 'THEME_TYPE_SEMI_DARK';
  const navStyle = 'NAV_STYLE_FIXED';
  const width = 1367;
  
  const onToggleCollapsedNav = () => {
    setNavCollapsed(!navCollapsed)
  };

  return (
    <Sider
      className={`gx-app-sidebar gx-layout-sider-dark ${navCollapsed ? 'gx-collapsed-sidebar' : ''}`}
      trigger={null}
      collapsed={(width < 992 ? false : navStyle === NAV_STYLE_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR)}
      theme={"dark"}
      collapsible>
      {
        navStyle === NAV_STYLE_DRAWER || width < 992 ?
          <Drawer
            className={'gx-drawer-sidebar gx-drawer-sidebar-light'}
            placement="left"
            closable={false}
            onClose={onToggleCollapsedNav}
            visible={navCollapsed}>
            <SidebarContent/>
          </Drawer> :
          <SidebarContent/>
      }
    </Sider>)
};
export default Sidebar;