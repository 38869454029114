import axios from  "axios";
import { authHeader } from "../../helper/Auth";

const url = process.env.REACT_APP_BASE_URL;


export const getNotifications = async () => {
    try {
      const response = await axios.get(`${url}/api/getnotifications`,{
        headers: authHeader()
      });
      return response.data;
    } catch (error) {
      throw new Error('Error fetching notifications',error);
    }
};

export const addNotification = async (data) => {
  try {
    const response = await axios.post(`${url}/api/createnotifi`,data,{
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching notifications',error);
  }
};

export const sendNotification = async (id) => {
  try {
    const response = await axios.post(`${url}/api/send/${id}`,{
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching notifications',error);
  }
};

export const deleteNotification = async (id) => {
  try {
    const response = await axios.delete(`${url}/api/deletenotification/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching notification',error);
  }
};